import React, { useState } from 'react';
import styled from 'styled-components';
import { ApiSelect } from '@src/modules/index/styled';
import Docs from '@src/templates/docs';
import SelectTransactionsIcon from '@src/layout/header/assets/select-transactions.svg';
import { parseApiCode } from '@src/modules/index';
import { ProgramType, programTypes } from '@src/modules/products/types';
import { breakpoints, colours } from '@src/components/styled/variables';
import { FeatureCTA } from '@src/components/styled';
import CodeWindow from '@src/components/code/code-window';

const { Option } = ApiSelect;

const featureCTAStyles = {
  margin: '24px 0 0',
  display: 'flex',
};

export default function Overview() {
  const [apiCode, setApiCode] = useState<ProgramType>('select-transactions');

  return (
    <Docs location={{ pathname: '/docs/overview' }} hideFooter>
      <h1>Overview</h1>
      <Description>
        Fidel API provides a core product, Select Transactions API. Select
        Transactions API allows you to see and act on enriched, accurate data
        about transactions made at participating stores with linked cards.
      </Description>

      <Container className="row">
        <div className="column" style={{ padding: '27px' }}>
          <a
            href="/docs/select"
            className="content"
            data-path="/docs/select"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <SelectTransactionsIcon />
            <CardSubTitle>Loyalty experiences</CardSubTitle>
            <CardTitle data-no-link style={{ marginTop: 0, marginBottom: 0 }}>
              Select Transactions API
            </CardTitle>
            <CardDescription style={{ marginTop: '1rem', marginBottom: 0 }}>
              Enriched, real-time payment data provided by Fidel API&apos;s
              network partners. Enables real-time loyalty experiences when
              enrolled cards transact at participating merchants.
            </CardDescription>
            <FeatureCTA
              style={featureCTAStyles}
              variant="primary"
              as="a"
              href="/docs/select"
            >
              <FeatureCTALabel>
                Read the Select Transactions docs
              </FeatureCTALabel>
            </FeatureCTA>
            <FeatureCTA
              style={featureCTAStyles}
              variant="secondary"
              as="a"
              href="https://reference.fidel.uk/"
            >
              <FeatureCTALabel>Go to the API Reference</FeatureCTALabel>
            </FeatureCTA>
          </a>
        </div>
      </Container>
      <CodeSnippetDescription>
        Here are examples of the Transaction object from Select Transaction API.
      </CodeSnippetDescription>
      <CodeWindow
        title="Fidel API Transaction Object"
        value={parseApiCode[apiCode].code}
        dark={false}
        rightElement={
          <ApiSelect
            dark={false}
            value={apiCode}
            onChange={value => setApiCode(value as ProgramType)}
          >
            {programTypes.map(value => (
              <Option key={value} value={value}>
                {parseApiCode[value].name}
              </Option>
            ))}
          </ApiSelect>
        }
      />
    </Docs>
  );
}

const Container = styled.div`
  &.row .column {
    width: 100%;
  }

  svg {
    width: 56px;
    height: 56px;

    path {
      stroke-width: 1;
    }
  }

  @media (min-width: 950px) {
    &.row .column {
      width: calc(50% - 10px);
    }
  }
`;

const Description = styled.p`
  margin-bottom: 24px;
`;

const CardTitle = styled.h2`
  font-size: 20px;
`;

const CardDescription = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: ${colours.darkGrey};
`;

const CardSubTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.6;
  color: ${colours.blue} !important;
`;

const CodeSnippetDescription = styled.p`
  margin-bottom: 1.5em;
`;

const FeatureCTALabel = styled.span`
  font-weight: 700;
  line-height: 24px;
  font-size: 12px;

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 14px;
  }
`;
